import React, { useState } from 'react';
// @ts-ignore
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
} from '../ui/table';
// @ts-ignore
import ManageSkillCategoryTableRowComponent from './skillscategories-row.component';

function ManageSkillCategoriesTable({ filteredSkillCategories, getSkillCategories }: any) {
  const tableHeaders = [
    'Name',
    ''
  ];

  return (
    <Table >
      <TableHeader>
        <TableRow>
          {tableHeaders.map((header) => (
            <TableHead key={header}>{header}</TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {filteredSkillCategories && filteredSkillCategories.length
          ? filteredSkillCategories.map((skillCategory: any, index: number) => {
              return (
                <ManageSkillCategoryTableRowComponent
                  skillCategory={skillCategory}
                  index={index}
                  getSkillCategories={getSkillCategories}
                />
              );
            })
          : 'No Skill Categories Found'}
      </TableBody>
    </Table>
  );
}

export default ManageSkillCategoriesTable;
