import React from 'react';
import { useState, useEffect } from 'react';
import { Button } from '../ui/button';
import { Trash2 } from 'lucide-react';
import useConfirm from '../ui/confirm-dialog';
import {
  TableRow,
  TableCell,
} from '../ui/table';
import SkillCategoryService from '../../utils/services/skillcategories-service';

function ManageSkillCategoryTableRowComponent({ skillCategory, index, getSkillCategories }) {
  const { confirm } = useConfirm();

  const [updateSkillCategoryParams, setUpdateSkillCategoryParams] = useState({
    id: '',
    name: ''
  });

  useEffect(() => {
    setUpdateSkillCategoryParams({
        id: skillCategory.id,
        name: skillCategory.name
    });
  }, []);

  useEffect(() => {
    setUpdateSkillCategoryParams({
        id: skillCategory.id,
        name: skillCategory.name
    });
  }, [skillCategory]);

  const deleteSkill = async () => {
    const hasConfirmed = await confirm({
      destructiveLabel: 'Delete',
      title: 'Delete skill category',
      description: 'Are you sure you want to delete this skill category?',
      cancelLabel: 'Cancel',
    });
    if (hasConfirmed) {
      await SkillCategoryService.delete(updateSkillCategoryParams.id);
      await getSkillCategories();
    }
  };

  return (
    <>
      <TableRow key={index}>
        <TableCell>{skillCategory.name}</TableCell>
        <TableCell className='md:space-x-1'>
          <Button
            variant={'link-neutral'}
            onClick={deleteSkill}
            className='space-x-2'
          >
            <Trash2 className='w-5 h-5' />
            <p>Delete</p>
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default ManageSkillCategoryTableRowComponent;
