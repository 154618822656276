import React, { useContext, useState } from 'react';
// @ts-ignore
import pyyneLogo from '../../assets/images/logo-high-res-tree-only.png';
// @ts-ignore
import { CurrentUserContext } from '../../utils/contexts/current-user.context';
// @ts-ignore
import useQuery from '../../utils/hooks/useQuery';
import { Button } from '../ui/button';
import { useNavigate } from "react-router-dom";

function ResetPasswordComponent({ ResetPassword, Error }: any) {
  const navigate = useNavigate()

  const [resetPasswordInfo, setResetPasswordInfo] = useState({
    password: '',
    passwordConfirmation: '',
  });

  // @ts-ignore
  const { getAxios } = useContext(CurrentUserContext);

  let query = useQuery();

  const submitHandler = async (e: any) => {
    e.preventDefault();
    let passwordsWithToken = resetPasswordInfo;
    // @ts-ignore
    passwordsWithToken['token'] = query.get('token');
    const response = await ResetPassword(getAxios(), passwordsWithToken);
    if (response) {
      navigate("/")
    }
  };

  return (
    <div className='flex relative w-full max-w-sm justify-center items-center p-8 rounded-2xl bg-[#E7FADACA]'>
      <form onSubmit={submitHandler}>
        <img src={pyyneLogo} alt='PYYNE Logo' className='mx-auto h-auto w-2/5' />
        <h2 className='text-center'>Reset Password</h2>
        <div className='flex m-1 p-1'>
          <input
            type='password'
            name='password'
            id='password'
            placeholder='Password'
            onChange={(e) =>
              setResetPasswordInfo({
                ...resetPasswordInfo,
                password: e.target.value,
              })
            }
            value={resetPasswordInfo.password}
            className='w-full p-1 rounded-lg'
          />
        </div>
        <div className='flex m-1 p-1'>
          <input
            type='password'
            name='password-confirmation'
            id='password-confirmation'
            placeholder='Password Confirmation'
            onChange={(e) =>
              setResetPasswordInfo({
                ...resetPasswordInfo,
                passwordConfirmation: e.target.value,
              })
            }
            value={resetPasswordInfo.passwordConfirmation}
            className='w-full p-1 rounded-lg'
          />
        </div>
        <div className='flex m-2 justify-center items-center p-1'>
          <Button type='submit' variant={'default'}>
            Reset Password
          </Button>
        </div>
        <Button variant={'link'} to='/login'>
          Have an account?
        </Button>
      </form>
    </div>
  );
}

export default ResetPasswordComponent;
