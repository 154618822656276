import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '../ui/dialog';
import { Input } from '../ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Button } from '../ui/button';
import { useSkillCategories } from '../../utils/hooks/api/skills/useSkillCategories';
import { RawSkill } from '../../types';

function CreateSkillModal({
  open,
  handleClose,
  createParams,
  setCreateParams,
  onSubmit,
}: {
  open: boolean;
  handleClose: () => void;
  createParams: RawSkill;
  setCreateParams: (data: RawSkill) => void;
  onSubmit: () => void;
}) {

  const { parsedSkillCategories } = useSkillCategories([]);

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className='max-w-screen-sm '>
        <DialogHeader>
          <DialogTitle>Create Skill</DialogTitle>
        </DialogHeader>

        <div className='flex flex-col gap-4'>
          <Input
            type='text'
            value={createParams.name}
            placeholder='Name'
            onChange={(e) =>
              setCreateParams({ ...createParams, name: e.target.value })
            }
          />  
          
          <Select
            value={createParams.categoriesIds.at(0)}
            onValueChange={(option) =>
              setCreateParams({ ...createParams, categoriesIds: [option] })
            }>
            <SelectTrigger>
              <SelectValue placeholder='Select a category' />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {parsedSkillCategories.map((category) => {
                  return (
                    <SelectItem key={category.id} value={category.id}>
                      {category.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>

          <div className='flex items-center justify-center'>
            <Button onClick={onSubmit}>Create</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CreateSkillModal;
