import { React, useState, useEffect, useContext } from 'react';
import UpdateUserModal from './update-user-modal.component';
import UserService from '../../utils/services/user-service';
import { CurrentUserContext } from '../../utils/contexts/current-user.context';
import { Button } from '../ui/button';
import { Pencil, Send, Trash2 } from 'lucide-react';
import useConfirm from '../ui/confirm-dialog';
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '../ui/table';

function ManageUsersTableRowComponent({ user, index, getUsers }) {
  const { getAxios } = useContext(CurrentUserContext);
  const axios = getAxios();
  const { confirm } = useConfirm();

  const [updateOpen, setUpdateOpen] = useState(false);
  const handleUpdateOpen = () => {
    setUpdateUserParams({
      id: user.id,
      name: user.name,
      email: user.email,
      role: user.role,
    });
    setUpdateOpen(true);
  };
  const handleUpdateClose = () => {
    setUpdateUserParams({
      id: user.id,
      name: user.name,
      email: user.email,
      role: user.role,
    });
    setUpdateOpen(false);
  };

  const [updateUserParams, setUpdateUserParams] = useState({
    id: '',
    name: '',
    email: '',
    role: '',
  });

  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    setUpdateUserParams({
      id: user.id,
      name: user.name,
      email: user.email,
      role: user.role,
    });
  }, []);

  useEffect(() => {
    setUpdateUserParams({
      id: user.id,
      name: user.name,
      email: user.email,
      role: user.role,
    });
  }, [user]);

  const updateUser = async () => {
    const { status } = await UserService.update(axios, updateUserParams);
    if (status) {
      await getUsers();
      handleUpdateClose();
    }
  };

  const deleteUser = async () => {
    const hasConfirmed = await confirm({
      destructiveLabel: 'Delete',
      title: 'Delete user',
      description: 'Are you sure you want to delete this user?',
      cancelLabel: 'Cancel',
    });
    if (hasConfirmed) {
      const { status } = await UserService.delete(axios, updateUserParams.id);
      await getUsers();
    }
  };

  const resendInvite = async () => {
    await UserService.resendInvite(axios, user.email);
  };

  return (
    <>
      <TableRow key={index}>
        <TableCell>{user.name}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>
          {user.inviteToken ? <td>Pending</td> : <td>Active</td>}
        </TableCell>
        <TableCell>{user.role}</TableCell>
        <TableCell>
          {user.inviteToken ? (
            <Button
              variant={'link-neutral'}
              onClick={resendInvite}
              className='space-x-2'
            >
              <Send className='w-5 h-5' />
              <p>Resend email</p>
            </Button>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell className='md:space-x-4'>
          <Button
            variant={'link-neutral'}
            onClick={handleUpdateOpen}
            className='space-x-2'
          >
            <Pencil className='w-5 h-5' />
            <p>Edit</p>
          </Button>
          <Button
            variant={'link-neutral'}
            onClick={deleteUser}
            className='space-x-2'
          >
            <Trash2 className='w-5 h-5' />
            <p>Delete</p>
          </Button>
        </TableCell>
      </TableRow>

      <UpdateUserModal
        open={updateOpen}
        handleClose={handleUpdateClose}
        onSubmit={updateUser}
        updateParams={updateUserParams}
        setUpdateParams={setUpdateUserParams}
      />
    </>
  );
}

export default ManageUsersTableRowComponent;
