import { AxiosError } from 'axios';
import { axios } from './axios';
import toast from 'react-hot-toast';
import { getToken } from './helper';
import { RawSkill, Skill } from '../../types';

const SkillService = {
  list: async function (queryParams: { filter?: string }) {
    return axios
      .get<Skill[]>('/skills', { params: queryParams, headers: { authorization: getToken() } })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  create: async function (roleData: RawSkill) {
    return axios
      .post<RawSkill>('/skills', roleData, {
        headers: { Authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
  delete: async function (skillId: string) {
    return axios
      .delete<string>(`/skills/${skillId}`, {
        headers: { authorization: getToken() },
      })
      .then((response) => response.data)
      .catch((error: AxiosError | any) => {
        let errorMessage;
        if (error.response && error.response.data) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = 'Connection failed';
        }
        toast.error(errorMessage);
        throw error;
      });
  },
};

export default SkillService;
