import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '../ui/dialog';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { RawSkillCategory } from '../../types';

function CreateSkillCategoryModal({
  open,
  handleClose,
  createParams,
  setCreateParams,
  onSubmit,
}: {
  open: boolean;
  handleClose: () => void;
  createParams: RawSkillCategory;
  setCreateParams: (data: RawSkillCategory) => void;
  onSubmit: () => void;
}) {

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className='max-w-screen-sm '>
        <DialogHeader>
          <DialogTitle>Create Skill Category</DialogTitle>
        </DialogHeader>

        <div className='flex flex-col gap-4'>
          <Input
            type='text'
            value={createParams.name}
            placeholder='Name'
            onChange={(e) =>
              setCreateParams({ ...createParams, name: e.target.value })
            }
          />  

          <div className='flex items-center justify-center'>
            <Button onClick={onSubmit}>Create</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CreateSkillCategoryModal;
