import React from 'react';
import { useState, useEffect } from 'react';
import { Button } from '../ui/button';
import { Trash2 } from 'lucide-react';
import useConfirm from '../ui/confirm-dialog';
import {
  TableRow,
  TableCell,
} from '../ui/table';
import SkillService from '../../utils/services/skills-service';

function ManageSkillTableRowComponent({ skill, index, getSkills }) {
  const { confirm } = useConfirm();

  const [updateSkillParams, setUpdateSkillParams] = useState({
    id: '',
    name: '',
    categories: []
  });

  useEffect(() => {
    setUpdateSkillParams({
        id: skill.id,
        name: skill.name,
        categories: skill.categories,
    });
  }, []);

  useEffect(() => {
    setUpdateSkillParams({
        id: skill.id,
        name: skill.name,
        categories: skill.categories,
    });
  }, [skill]);

  const deleteSkill = async () => {
    const hasConfirmed = await confirm({
      destructiveLabel: 'Delete',
      title: 'Delete skill',
      description: 'Are you sure you want to delete this skill?',
      cancelLabel: 'Cancel',
    });
    if (hasConfirmed) {
      await SkillService.delete(updateSkillParams.id);
      await getSkills();
    }
  };

  return (
    <>
      <TableRow key={index}>
        <TableCell>{skill.name}</TableCell>
        <TableCell>{skill.categories?.at(0)?.name}</TableCell>
        <TableCell className='md:space-x-1'>
          <Button
            variant={'link-neutral'}
            onClick={deleteSkill}
            className='space-x-2'
          >
            <Trash2 className='w-5 h-5' />
            <p>Delete</p>
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default ManageSkillTableRowComponent;
