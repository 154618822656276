import React, { useState } from 'react';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '../components/ui/tabs';
// @ts-ignore
import ColorSettings from '../components/settings-page/color-settings.component';
// @ts-ignore
import ManageUsers from '../components/settings-page/manage-users.component';
// @ts-ignore
import { PlusCircle, Trash2 } from 'lucide-react';
import OfficeSettings from '../components/settings-page/office-settings.component';
import { Button } from '../components/ui/button';
import { useRoles } from '../utils/hooks/api/roles/useRoles';

import AddNewRoleModal from '../components/settings-page/add-new-role-modal.component';
import useConfirm from '../components/ui/confirm-dialog';
import { useDeleteRole } from '../utils/hooks/api/roles/useDeleteRole';
import ManageSkills from '../components/skills-page/manage-skills.component';
import ManageSkillCategories from '../components/skills-page/manage-skillcategories.component';

function SettingsPage() {
  return (
    <div className='space-y-8'>
      <h1 className='text-primary'>Settings</h1>
      <div>
        <Tabs defaultValue='users'>
          <TabsList className='pb-8'>
            <TabsTrigger value='users'>Users</TabsTrigger>
            <TabsTrigger value='skills'>Skills</TabsTrigger>
            <TabsTrigger value='others'>Others</TabsTrigger>
          </TabsList>
          <TabsContent value='users'>
            <ManageUsers />
          </TabsContent>
          <TabsContent className='space-y-12' value='skills'>
            <div className="flex flex-row flex-wrap">
              <div className="m-4"><ManageSkills /></div>
              <div className="m-4"><ManageSkillCategories /></div>
            </div>
          </TabsContent>
          <TabsContent className='space-y-12' value='others'>
            <OfficeSettings />
            <div className='grid gap-4 lg:grid-cols-2'>
              <ColorSettings />
              <RoleSettings />
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}

interface RoleSettingsProps {}
const RoleSettings: React.FC<RoleSettingsProps> = () => {
  const [open, setOpen] = useState(false);
  const { confirm } = useConfirm();
  const handleModal = () => setOpen(!open);

  const { roles } = useRoles();
  const { mutate: deleteRoleMutation } = useDeleteRole();
  const deleteRole = async (roleId: string) => {
    const hasConfirmed = await confirm({
      destructiveLabel: 'Delete',
      title: 'Delete role',
      description:
        'Are you sure you want to delete this role? It will also remove it from all consultants that have it assigned',
      cancelLabel: 'Cancel',
    });
    if (hasConfirmed) {
      deleteRoleMutation(roleId);
    }
  };
  return (
    <>
      <div className=''>
        <div className='h-full p-8 space-y-4 bg-white rounded-lg'>
          <h2>Roles</h2>

          <div className='flex flex-col gap-6 md:gap-4'>
            {roles && !!roles.length ? (
              roles.map((role) => {
                return (
                  <div className='flex flex-row items-center justify-between'>
                    <div>{role.name}</div>
                    <Button
                      className='space-x-2'
                      variant='link'
                      onClick={() => {
                        deleteRole(role.id);
                      }}
                    >
                      <Trash2 />
                      <p>Delete</p>
                    </Button>
                  </div>
                );
              })
            ) : (
              <>{'No status Found'}</>
            )}
            <div>
              <Button
                className='mt-4 space-x-2'
                variant='link'
                onClick={handleModal}
              >
                <PlusCircle />
                <p>Add new role</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <AddNewRoleModal open={open} handleClose={handleModal} />
    </>
  );
};
export default SettingsPage;
