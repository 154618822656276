import React from 'react';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from './drawer';
import { Button } from './button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from './command';
import { Check, ChevronDown } from 'lucide-react';
import { cn } from '../../utils/helpers';
import { ValueAndLabel } from '../../types';
import { ScrollArea } from './scroll-area';

interface MobileSelectProps {
  className?: string;
  options: ValueAndLabel[];
  selectName: string;
  useLabelAsSelected?: boolean;
  value: string | undefined;
  disabled?: boolean
  onSelectValue: (value: string) => void;
}

export const MobileSelect: React.FC<MobileSelectProps> = ({
  options,
  value,
  className,
  useLabelAsSelected,
  selectName,
  disabled,
  onSelectValue,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild className={cn(className)} >
        <Button
          variant='outline-neutral'
          aria-expanded={open}
          className={cn(
            'min-w-[200px] relative hover:bg-white justify-between',
            value && 'text-black',
            className
          )}
          disabled={disabled}
        >
          {value ? (
            <p>
              {' '}
              {useLabelAsSelected
                ? options
                    .find((availOption) => availOption.value === value)
                    ?.label.slice(0, 5) + '..'
                : value}
            </p>
          ) : (
            <>
              <p className=' text-grey-300'>{selectName}</p>
            </>
          )}
          <ChevronDown className='w-6 h-6 ml-2 text-grey-300 shrink-0' />
        </Button>
      </DrawerTrigger>
      <DrawerContent className=''>
        <ScrollArea maxHeight='max-h-[80vh]' className={cn('')}>
          <Command>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  className='h-11'
                  aria-checked={value === option.value ? 'true' : 'false'}
                  value={option.value}
                  onSelect={() => {
                    onSelectValue(option.value);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      value === option.value ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </ScrollArea>
      </DrawerContent>
    </Drawer>
  );
};
