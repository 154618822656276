import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from '../ui/button';
import { Loader } from '../ui/loader';
import SkillCategoriesService from '../../utils/services/skillcategories-service';
import { RawSkillCategory, SkillCategory } from '../../types';
import CreateSkillCategoryModal from './skillcategories-create-modal.component';
import ManageSkillCategoriesTable from './skillcategories-table.component';


function ManageSkillCategories() {

  const [filteredSkillCategories, setFilteredSkillCategories] = useState([] as SkillCategory[]);

  // Invite Modal variables
  const [createSkillCategoryOpen, setCreateSkillCategoryOpen] = useState(false);
  const handleCreateSkillCategoryOpen = () => setCreateSkillCategoryOpen(true);
  const handleCreateSkillCategoryClose = () => setCreateSkillCategoryOpen(false);

  const [createSkillCategoryParams, setCreateSkillCategoryParams] = useState({ name: '', categoriesIds:[] } as RawSkillCategory);

  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    getSkillCategories();
  }, []);

  function validateCreateSkillCategoryParams() {
    return (
      (!createSkillCategoryParams.name) ? "Name is a required field" :
      undefined
    )
  }

  async function getSkillCategories() {
    setIsloading(true);
    const skillCategories = await SkillCategoriesService.list();
    
    setFilteredSkillCategories(skillCategories);
    setIsloading(false);
  }

  const createSkillCategory = async () => {
    const errorMsg = validateCreateSkillCategoryParams()

    if (errorMsg) {
      toast.error(errorMsg);
    } else {
      const skillcategory = await SkillCategoriesService.create(createSkillCategoryParams);
      if (skillcategory) {
        await getSkillCategories();
        setCreateSkillCategoryParams({ name: '' }); // TODO
        handleCreateSkillCategoryClose();
      }
    }
  };

  return (
    <div className='space-y-9'>
      <div className='flex flex-col-reverse justify-between gap-4 md:flex-row'>
        <div className='page-header flex items-end'>
          <Button onClick={handleCreateSkillCategoryOpen}>Create Skill Category</Button>
        </div>
      </div>
      <Loader loading={isLoading} />
      <ManageSkillCategoriesTable filteredSkillCategories={filteredSkillCategories} getSkillCategories={getSkillCategories} />
      <CreateSkillCategoryModal
        open={createSkillCategoryOpen}
        handleClose={handleCreateSkillCategoryClose}
        onSubmit={createSkillCategory}
        createParams={createSkillCategoryParams}
        setCreateParams={setCreateSkillCategoryParams}
      />
    </div>
  );
}

export default ManageSkillCategories;