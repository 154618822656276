import { Search } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Loader } from '../ui/loader';
import SkillService from '../../utils/services/skills-service';
import { RawSkill, Skill } from '../../types';
import CreateSkillModal from './skills-create-modal.component';
import ManageSkillsTable from './skills-table.component';


function ManageSkills() {

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSkills, setFilteredSkills] = useState([] as Skill[]);

  // Invite Modal variables
  const [createSkillOpen, setCreateSkillOpen] = useState(false);
  const handleCreateSkillOpen = () => setCreateSkillOpen(true);
  const handleCreateSkillClose = () => setCreateSkillOpen(false);

  const [createSkillParams, setCreateSkillParams] = useState({ name: '', categoriesIds:[] } as RawSkill);

  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    getSkills();
  }, []);

  useEffect(() => {
    getSkills();
  }, [searchQuery]);

  function validateCreateSkillParams() {
  
    return (
      (!createSkillParams.name) ? "Name is a required field" :
      (!createSkillParams.categoriesIds.length) ? "Select a category" :
      undefined
    )
  }

  async function getSkills() {
    setIsloading(true);
    const queryParams = {
      filter: searchQuery,
    };
    const skills = await SkillService.list(queryParams);
    
    setFilteredSkills(skills);
    setIsloading(false);
  }

  const createSkill = async () => {
    const errorMsg = validateCreateSkillParams()

    if (errorMsg) {
      toast.error(errorMsg);
    } else {
      const skill = await SkillService.create(createSkillParams);
      if (skill) {
        await getSkills();
        setCreateSkillParams({
          name: '',
          categoriesIds: []
        });  // TODO
        handleCreateSkillClose();
      }
    }
  };

  return (
    <div className='space-y-8'>
      <div className='flex flex-col-reverse items-center justify-between gap-4 md:flex-row'>
        <Input
          className='w-[260px]'
          leadingIcon={<Search className='w-4 h-4' />}
          value={searchQuery}
          placeholder={`Search for Skill's name`}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <div className='page-header'>
          <Button onClick={handleCreateSkillOpen}>Create Skill</Button>
        </div>
      </div>
      <Loader loading={isLoading} />
      <ManageSkillsTable filteredSkills={filteredSkills} getSkills={getSkills} />
      <CreateSkillModal
        open={createSkillOpen}
        handleClose={handleCreateSkillClose}
        onSubmit={createSkill}
        createParams={createSkillParams}
        setCreateParams={setCreateSkillParams}
      />
    </div>
  );
}

export default ManageSkills;