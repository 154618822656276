import { AxiosError } from 'axios';
import { axios } from './axios';
import toast from 'react-hot-toast';
import { RawSkillCategory, SkillCategory } from '../../types';
import { getToken } from './helper';

function callEndpoint<T>(block: ()=>Promise<T>): Promise<T> {
  try {
    return block()
  }
  catch(error: AxiosError | any) {
    let errorMessage;
    if (error.response && error.response.data) {
      errorMessage = error.response.data.error;
    } else {
      errorMessage = 'Connection failed';
    }
    toast.error(errorMessage);
    throw error;
  }
}

const SkillCategoryService = {
    list: async function (queryParams?: { names?: string[] }) {
      return (
        await callEndpoint(() => {
          return axios.get<SkillCategory[]>(
            '/skill-categories', 
            { params: queryParams, headers: { authorization: getToken() } }
          )
      })).data
    },
    create: async function (skillCategoryData: RawSkillCategory) {
      return ( 
        await callEndpoint(() => { 
          return axios.post<RawSkillCategory>(
            '/skill-categories', 
            skillCategoryData, 
            { headers: { Authorization: getToken() }}
          )
      }))
    },
    delete: async function (skillCategoryId: string) {
      return ( 
        await callEndpoint(() => {
          return axios.delete<string>(
            `/skill-categories/${skillCategoryId}`, 
            { headers: { authorization: getToken() }}
          )
      }))
    },
};

export default SkillCategoryService;