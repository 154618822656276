import React, { useState } from 'react';
// @ts-ignore
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
} from '../ui/table';
// @ts-ignore
import ManageSkillTableRowComponent from './skills-row.component';

function ManageSkillsTable({ filteredSkills, getSkills }: any) {
  const tableHeaders = [
    'Name',
    'Category',
    ''
  ];

  return (
    <Table >
      <TableHeader>
        <TableRow>
          {tableHeaders.map((header) => (
            <TableHead key={header}>{header}</TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {filteredSkills && filteredSkills.length
          ? filteredSkills.map((skill: any, index: number) => {
              return (
                <ManageSkillTableRowComponent
                  skill={skill}
                  index={index}
                  getSkills={getSkills}
                />
              );
            })
          : 'No Skills Found'}
      </TableBody>
    </Table>
  );
}

export default ManageSkillsTable;
